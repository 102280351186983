<template>
    <div class="pb-10 h-100">
        <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
            <div class="breadcrumbactive">
                <div >
                    <span>Manage Company </span>
                    <div>List View</div>
                </div>
            </div>
            <div>
                <div class="d-flex align-items-center">
                    <!-- <button type="button" class="btn btn-smm btn-new-secondary" style="border: 0 !important;border-radius: 6px !important;">
                        <img src="/static/images/filter.svg" />
                        Filter
                    </button> -->
                    <button v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" type="button" @click="showDeleteComp" :disabled="comp_selected.length <= 0" class="btn btn-smm btn-danger ml-3" style="border-radius: 6px !important;">
                        <i class="icon icon-minus fs-12 text-white"></i>
                        Delete
                    </button>
                    <!-- <button type="button" @click="$router.push('/organization/create')" class="btn btn-smm text-white btn-success ml-3" style="border-radius: 6px !important;">
                        <i class="icon icon-plus fs-12 text-white" style="color: #fff !important;"></i>
                        Create Organization
                    </button> -->
                </div>
            </div>
        </div>
        <div class="pr-2">
            <div class="w-100">
                <div class="d-flex align-items-center" >
                    <div class="d-flex align-items-center" >
                        <div class="header-slant-tab">
                            <span class="text-secondary fs-14">List View</span>
                        </div>
                        <div class="header-slant-tab-border"></div>
                    </div>
                    <div class="ml-3 d-flex align-items-center w-100" style="position: relative;">
                        <i class="icon icon-magnify fs-15" style="position: absolute; left: 10px;"></i>
                        <input type="text" class="search-input-field" v-model="search_key" v-debounce:500ms="getListViewData" debounce-events="input" placeholder="Search by Branch Name, Company Name" />
                    </div>
                    <!-- <div class="d-flex justify-content-center ml-2" style="background: rgb(255, 255, 255); border-radius: 20px; width: 150px; height: 2.9em;">
                        <div class="d-flex justify-content-center align-items-center pointer pointer-events-none" style="padding: 0 10px; border-right: 0.5px solid rgb(204, 227, 252); opacity: 0.5;">
                            <img src="/static/images/grid_new.svg" width="18">
                        </div>
                        <div class="d-flex justify-content-center align-items-center pointer" style="padding: 0 10px; border-left: 0.5px solid rgb(204, 227, 252);">
                            <img src="/static/images/filter_bars_new.svg" width="18">
                        </div>
                    </div> -->
                </div>
                <div class="table-start" style="min-height: 30em;">
                    <table class="admin-new-table w-100" style="border-collapse: collapse; position: relative;" width="100%">
                        <thead>
                            <tr>
                                <th class="text-center">
                                    <input v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" type="checkbox" v-model="check_all" @change="checkAll" name="select_all" />
                                </th>
                                <!-- <th></th> -->
                                <th>
                                    Company Name
                                </th>
                                <th>
                                    Incorporation date
                                </th>
                                <th>
                                    Headquarters
                                </th>
                                <th>
                                    Branch Name
                                </th>
                                <th>
                                    Industry Type
                                </th>
                                <th>
                                    Primary E-mail
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="ajax_call_in_progress">
                                <td colspan="8">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="!ajax_call_in_progress && list_view_data.length == 0" class="text-center font-weight-bold">
                                <td colspan="8">No Companies Found</td>
                            </tr>
                            <tr v-else v-for="(comp, index) in list_view_data" :key="comp.company_id">
                                <td class="text-center">
                                    <input v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" type="checkbox" v-model="comp.checked" @change="selectComp($event, comp.company_id, index)" name="select_one" />
                                </td>
                                <!-- <td>
                                    <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                                        <button class="pointer btn-icon pt-3" @click="editCompany(comp.company_id)" style="padding:0px!important;">
                                            <img src="/static/images/edit-new.svg" height="15px;">
                                        </button>
                                    </el-tooltip>
                                </td> -->
                                <td>
                                    <span class="pointer text-secondary" @click="goToCompanyView(comp.company_id)">{{comp.company_name || "-"}}</span></td>
                                <td v-if="comp.date_of_incorporation">{{moment(comp.date_of_incorporation).format("DD/MM/YYYY") || "-"}}</td>
                                <td v-else>-</td>
                                <td>{{comp.head_quarters || "-"}}</td>
                                <td v-if="comp.branch_name.length > 0">
                                    {{comp.branch_name[0] || "-"}}
                                    <span v-if="comp.branch_name.length > 1" class="text-secondary fs-12 font-weight-bold">+{{comp.branch_name.length-1}} More</span>
                                </td>
                                <td v-else>
                                    {{"-"}}
                                </td>
                                <td>{{comp.industry_type || "-"}}</td>
                                <td>{{comp.primary_email || "-"}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-flex justify-content-end align-items-center bg-white py-2 px-4" style="border-bottom-left-radius: 5px; border-bottom-right-radius: 5px">
                    <multiselect class="diginew-multiselect"
                        :options="pageLimit" :close-on-select="true" v-model.number="limit"
                        open-direction="top" :show-labels="false" placeholder=""
                        style="width: 4.0em !important;padding-right: 10px!important;padding-top: 10px !important;"></multiselect>
                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/down-arrow-grey.svg" style="margin-left: -20px;">
                    <span class="page-info pr-2 pl-6 fw-500" style="height: 2.4rem;line-height: 2.4rem;color: #4e5764;font-size: 12px;">Per page</span>
                    <button @click="firstPage()" :disabled="skip == 0" class="mt-1 pointer pagination-list">
                        <el-tooltip class="item" effect="dark" content="First Page" placement="top">
                            <i class="icon-chevron-double-left"></i>
                        </el-tooltip>
                    </button>
                    <button @click="previousPage()" :disabled="skip == 0" class="pagination-list mt-1 ml-2 pointer">
                        <el-tooltip class="item" effect="dark" content="Previous Page" placement="top">
                            <i class="icon icon-chevron-left"></i>
                        </el-tooltip>
                    </button>
                    <span class="px-2 fw-500 fs-14 mt-1">Showing {{getSkipCount}} - {{total>skip+limit?skip+limit:total}} of {{total}}</span>
                    <button @click="nextPage()" :disabled="skip_temp >= total" class="pagination-list mt-1 mr-2 pointer">
                        <el-tooltip class="item" effect="dark" content="Next Page" placement="top">
                            <i class="icon icon-chevron-right"></i>
                        </el-tooltip>
                    </button>
                    <button @click="lastPage()" class="mt-1 pointer pagination-list" :disabled ="temp_total == total">
                        <el-tooltip class="item" effect="dark" content="Last Page" placement="top">
                            <i class="icon-chevron-double-right"></i>
                        </el-tooltip>
                    </button>
                </div>
            </div>
        </div>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
        <delete-company-modal v-if="comp_delete" @hideDeleteCompanyModal="hideDeleteCompanyModal" @deleteCompanySuccess="deleteCompanySuccess" modal_name='delete_company_modal' ></delete-company-modal>
    </div>
</template>
<script>
import organization from '../mixins/organization';
import {HalfCircleSpinner} from 'epic-spinners';
import moment from 'moment';
import { SweetModal } from 'sweet-modal-vue';
import companies from '../mixins/companies';
import DeleteCompanyModal from './DeleteCompanyModal.vue';
import { EventBus } from '../eventBus/nav-bar-event';
export default {
    mixins: [organization, companies],
    components: {
        HalfCircleSpinner,
        SweetModal,
        DeleteCompanyModal
    },
    data() {
        return {
            search_key: '',
            check_all: false,
            comp_delete: false,
            check_one: false,
            warning_msg: '',
            success_msg: '',
            skip:0,
            limit: 10,
            count: 0,
            pageLimit: [],
            start: 0,
            end: 0,
            total: 0,
            skip_temp:0,
            temp_total:0,
            list_view_data: [],
            ajax_call_in_progress: false,
            comp_selected: [],
            new_token: ''
        }
    },
    methods: {
        moment(date) {
            return moment(date);
        },
        setCookie(days) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = "Token" + "=" + (this.new_token || "") + expires + "; path=/";
            setTimeout(() => {
                window.location.reload();
            }, 500);
        },
        selectComp(event, id, index) {
            if(event.target.checked) {
                this.comp_selected.push(id);
            } else {
                let index = this.comp_selected.indexOf(id);
                if (index > -1) {
                    this.comp_selected.splice(index, 1);
                }
            }
            if(this.comp_selected.length === this.list_view_data.length) {
                this.check_all = true;
            } else {
                this.check_all = false;
            }
        },
        hideDeleteCompanyModal() {
            this.comp_delete = false;
            this.getListViewData();
        },
        async deleteCompanySuccess() {
            this.deleteComp();
        },
        checkAll(event) {
            if(event.target.checked === true) {
                this.list_view_data.forEach((comp, index) => {
                    this.list_view_data[index].checked = true;
                    this.comp_selected.push(comp.company_id);
                })
            } else {
                this.list_view_data.forEach((comp, index) => {
                    this.list_view_data[index].checked = false;
                })
                this.comp_selected = [];
            }
        },
        // editCompany(id) {
        //     this.$router.push('/company/editcompany/standalonecompany/'+id)
        // },
        previousPage() {
            this.temp_total = 0
            this.skip_temp = 0
            if (this.skip == 0) {
                return;
            }
            this.skip = this.skip - this.limit <= 0 ? 0 : this.skip - this.limit;
            this.getListViewData();
        },
        nextPage() {
            this.skip_temp = this.skip + this.limit > this.total ? this.total : this.skip + this.limit;
            if (this.skip_temp >= this.total) {
                return;
            }
            this.skip = this.skip_temp;
            this.getListViewData();
        },
        firstPage(){
            this.temp_total = 0
            this.skip = 0
            this.limit = this.limit
            this.list_view_data = []
            this.getListViewData();
        },
        lastPage() {
            this.skip_temp = 0
            this.skip = 0
            this.temp_total = this.total
            let no_of_pages = Math.ceil(this.total/this.limit)
            this.skip_temp = (no_of_pages * this.limit) - this.limit
            if (this.skip_temp >= this.total) {
                return;
            }
            this.skip = this.skip_temp
            this.getListViewData();
        },
        goToCompanyView(id) {
            this.$router.push('/company/view/cmpid/'+id);
            localStorage.setItem('comp_data_for_menubar', JSON.stringify({company_id: id}));
            EventBus.$emit('comp_api_call_for_menubar')
        },
        async getListViewData() {
            this.comp_selected = [];
            this.list_view_data = [];
            this.check_all = false;
            this.ajax_call_in_progress = true;
            try {
                let params = {
                    skip: this.skip,
                    limit: this.limit == "" || this.limit == 0 || this.limit < 0 ? 10 : this.limit,
                    search_key: this.search_key
                }
                let response = await this.getHierarchyListView(params);
                if(response.status_id == 1) {
                    this.list_view_data = response.response;
                    this.list_view_data.forEach((comp, index) => {
                        this.list_view_data[index].show = false;
                        this.list_view_data[index].checked = false;
                    })
                    this.total = response.total_count;
                }
                this.ajax_call_in_progress = false;
            } catch(err) {
                this.ajax_call_in_progress = false;
            }
        },
        showDeleteComp() {
            this.comp_delete = true;
            setTimeout(() => {
                this.$modal.show('delete_company_modal');
            }, 500);
        },
        async deleteComp() {
            try {
                let params = {
                    id: this.comp_selected
                }
                let response = await this.deleteCompany(params)
                if(response.status_id == 1) {
                    this.success_msg = response.message
                    EventBus.$emit('personal_info_data_update');
                    this.$refs.success_modal.open();
                    this.new_token = response.token;
                    if(response.token) {
                        this.setCookie(7);
                    }
                    setTimeout(() => {
                        this.$refs.success_modal.close();
                        this.getListViewData();
                    }, 2000);
                }
            }
            catch(err) {
                // this.ajax_call_in_progress = false;
                this.warning_msg = err;
                this.$refs.warning_modal.open();
            }
        }
    },
    mounted() {
        if(this.loggedInUser.has_organization_or_standalone) {
            if(this.loggedInUser.user_has == 'standalone' || this.loggedInUser.user_has == 'company') {
                this.$router.push('/company/listview');
            } else {
                this.$router.push('/organization/listview');
            }
        } else {
            this.$router.push('/company/createcompanyororganization');
        }
        this.getListViewData();
        // for (let i = 1; i <= 50; i++) {
        //     this.pageLimit.push(i);
        // }
        localStorage.removeItem('comp_data_for_menubar');
    },
    computed: {
        loggedInUser() {
            return this.$store.state.loggedInUser;
        },
        getSkipCount(){
            if(this.total == 0) {
                return 0
            } else {
                let skip_count = this.skip>this.total?this.total:this.skip+1;
                return skip_count;
            }
        },
    },
    created() {
        for (let i = 10; i <= 50; i+=10) {
            this.pageLimit.push(i);
        }
    },
    watch: {
        limit: function(){
            this.getListViewData()
        },
        // search_key: function() {
        //     this.getListViewData();
        // },
        // count: function() {
        //     this.getListViewData();
        // },
    },
}
</script>
<style scoped>
    .table-view-start {
        background-color: #fff;
    }
    .search-input-field {
        padding: 8px 9.4px 8px 30px;
        border-radius: 18px !important;
        background-color: #fff;
        width: 100%;
    }
    .table-start {
        background-color: #fff;
        padding-top: 5px;
        border-radius: 8px;
        border-top-left-radius: 0;
        overflow-x: auto !important;
    }
</style>